import { FormGroup, InputGroup } from '@blueprintjs/core';
import { Address, Business, createId } from '@humancollective/seedz-shared';
import { Formik, FormikProps } from 'formik';
import * as React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { Button, Colors as KitColors } from '@humancollective/uikit-web';

import * as Form from '../../../components/Form';
import { Imgix } from '../../../utilities';
import { saveBusiness } from '../../../utilities/firebase';
import NewImageUploader from '../../ImageUploader/New';
import {
  AMERICAN_STATES,
  CANADIAN_PROVINCES,
  DEFAULT_MARKER_IMAGE_PATH,
  SUPPORTED_COUNTRIES,
} from '../../../config';

const StyledForm = styled.form`
  max-width: 350px;
  .bp3-form-group {
    > label.bp3-label {
      color: ${KitColors.primary};
      font-size: 16px;
      font-weight: bold;
    }
  }
`;

const RowContent = styled.div`
  display: flex;
  flex-direction: row;

  > * {
    flex-grow: 1;
    :first-child {
      margin-right: 20px;
    }
  }
`;

const LogoContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > img {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    height: 75px;
    width: 75px;
    border-radius: 37.5px;
    margin-left: 15px;
    margin-right: 25px;
  }
`;

const DEFAULT_VALUES: Business = {
  id: createId(),
  name: '',
  logo: DEFAULT_MARKER_IMAGE_PATH,
  address: {
    streetAddress: '',
    region: '',
    postalCode: '',
    locality: '',
    country: 'US',
  },
  credits: 0,
};

const addressValidation = yup.object().shape<Address>({
  region: yup.string().required('Required'),
  postalCode: yup.string().required('Required'),
  locality: yup.string().required('Required'),
  streetAddress: yup.string().required('Required'),
  country: yup.string().required('Required'),
});

const validationSchema = yup.object().shape<Business>({
  name: yup.string().required('Required'),
  id: yup.string(),
  logo: yup.string(),
  credits: yup.number(),
  address: addressValidation,
});

interface BusinessDetailsFormProps {
  onSuccess?: (values: Business) => void;
}

export const BusinessDetailsForm: React.FunctionComponent<
  BusinessDetailsFormProps
> = ({ onSuccess }) => (
  <Formik
    initialValues={{ ...DEFAULT_VALUES }}
    onSubmit={async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        await saveBusiness(values);
        if (onSuccess) onSuccess(values);
        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
      }
    }}
    validationSchema={validationSchema}
    enableReinitialize={true}
  >
    {({
      isSubmitting,
      values,
      handleSubmit,
      setFieldValue,
      handleChange,
    }: FormikProps<Business>) => (
      <StyledForm onSubmit={handleSubmit}>
        <FormGroup label="Logo" inline={true} style={{ alignItems: 'center' }}>
          <LogoContent>
            <img
              src={Imgix.createUrl(values.logo, {
                height: 75,
                width: 75,
                cornerRadius: 37.5,
                mask: 'CORNERS',
              })}
              alt="Company logo"
            />
            <NewImageUploader
              onUploadSuccess={filePath => setFieldValue('logo', filePath)}
            />
          </LogoContent>
        </FormGroup>
        <FormGroup label="Name">
          <InputGroup
            name="name"
            placeholder="John Smith"
            autoComplete="name"
            required={true}
            value={values.name}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup label="Street Address">
          <InputGroup
            name="address.streetAddress"
            placeholder="18272 Brambleberry Dr"
            autoComplete="street-address"
            required={true}
            onChange={handleChange}
            value={values.address.streetAddress}
          />
        </FormGroup>
        <RowContent>
          <FormGroup label="City">
            <InputGroup
              name="address.locality"
              placeholder="Toronto"
              autoComplete="address-level2"
              required={true}
              value={values.address.locality}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup label="Postal Code">
            <InputGroup
              name="address.postalCode"
              placeholder="L1W 1G2"
              autoComplete="postal-code"
              required={true}
              value={values.address.postalCode}
              onChange={handleChange}
            />
          </FormGroup>
        </RowContent>
        <FormGroup label="Province / State">
          <Form.FieldSelect
            name="address.region"
            placeholder="Select a Province / State:"
            autoComplete="address-level1"
            options={[...AMERICAN_STATES, ...CANADIAN_PROVINCES]}
            required={true}
          />
        </FormGroup>
        <FormGroup label="Country">
          <Form.FieldSelect
            name="address.country"
            placeholder="Select a Country:"
            autoComplete="country"
            options={SUPPORTED_COUNTRIES}
            required={true}
          />
        </FormGroup>
        <Button type="submit" label="Save Details" disabled={isSubmitting} />
      </StyledForm>
    )}
  </Formik>
);

export default BusinessDetailsForm;

import { Button, FormGroup } from '@blueprintjs/core';
import { Address, Business, createId } from '@humancollective/seedz-shared';
import { Formik, FormikProps } from 'formik';
import * as React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

import * as Form from '../../Form';
import { Imgix } from '../../../utilities';
import { saveBusiness } from '../../../utilities/firebase';
import ImageUploader from '../../ImageUploader';
import {
  AMERICAN_STATES,
  CANADIAN_PROVINCES,
  DEFAULT_MARKER_IMAGE_PATH,
  SUPPORTED_COUNTRIES,
} from '../../../config';

const LogoContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > img {
    height: 75px;
    width: 75px;
    border-radius: 37.5px;
    margin-bottom: 5px;
    margin-right: 25px;
  }
`;

const initialValues: Business = {
  id: createId(),
  name: '',
  logo: DEFAULT_MARKER_IMAGE_PATH,
  address: {
    streetAddress: '',
    region: '',
    postalCode: '',
    locality: '',
    country: 'US',
  },
  credits: 0,
};

const addressValidation = yup.object().shape<Address>({
  region: yup.string().required('Required'),
  postalCode: yup.string().required('Required'),
  locality: yup.string().required('Required'),
  streetAddress: yup.string().required('Required'),
  country: yup.string().required('Required'),
});

const validationSchema = yup.object().shape<Business>({
  name: yup.string().required('Required'),
  id: yup.string(),
  logo: yup.string(),
  credits: yup.number(),
  address: addressValidation,
});

interface BusinessFormProps {
  business?: Business; // You can pass in an empty business, the initialvalues will create a new one
  onSubmitting?: (values: Business) => void | Promise<void>;
}

export const OldBusinessForm: React.FunctionComponent<BusinessFormProps> = ({
  business,
  onSubmitting,
}) => (
  <Formik
    initialValues={{
      ...initialValues,
      ...business,
    }}
    onSubmit={async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        await saveBusiness(values);
        if (onSubmitting) await onSubmitting(values);
        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
      }
    }}
    validationSchema={validationSchema}
  >
    {({
      isSubmitting,
      values,
      handleSubmit,
      setFieldValue,
    }: FormikProps<Business>) => (
      <Form.StyledForm onSubmit={handleSubmit}>
        <FormGroup label="Logo">
          <LogoContent>
            <img
              src={Imgix.createUrl(values.logo, {
                height: 75,
                width: 75,
                cornerRadius: 37.5,
                mask: 'CORNERS',
              })}
              alt="Company logo"
            />
            <ImageUploader
              onChange={({ imagePath }) => setFieldValue('logo', imagePath)}
            />
          </LogoContent>
        </FormGroup>
        <Form.FieldInput
          name="name"
          title="Name"
          placeholder="John Smith"
          autoComplete="name"
          required={true}
        />
        <Form.FieldInput
          name="address.streetAddress"
          title="Street Address"
          placeholder="123 Business St."
          autoComplete="street-address"
          required={true}
        />
        <Form.FieldGroup>
          <Form.FieldGroupItem>
            <Form.FieldInput
              name="address.locality"
              title="City"
              placeholder="City"
              autoComplete="address-level2"
              required={true}
            />
          </Form.FieldGroupItem>
          <Form.FieldGroupItem>
            <Form.FieldInput
              name="address.postalCode"
              title="Postal Code / ZIP"
              placeholder="X0X 0X0"
              autoComplete="postal-code"
              required={true}
            />
          </Form.FieldGroupItem>
        </Form.FieldGroup>
        <Form.FieldSelect
          name="address.region"
          title="Province / State"
          placeholder="Select a Province / State:"
          autoComplete="address-level1"
          options={[...AMERICAN_STATES, ...CANADIAN_PROVINCES]}
          required={true}
        />
        <Form.FieldSelect
          name="address.country"
          title="Country"
          placeholder="Select a Country:"
          autoComplete="country"
          options={SUPPORTED_COUNTRIES}
          required={true}
        />
        <Form.FieldInput
          name="metadata.website"
          title="Website Link"
          placeholder="https://website.com"
        />
        <Form.FieldInput
          name="metadata.phone"
          title="Phone Number"
          placeholder="999-999-9999"
        />
        <Form.FieldInput
          name="metadata.facebookUrl"
          title="Facebook Link"
          placeholder="https://facebook.com/your-page"
        />
        <Form.FieldInput
          name="metadata.instagramUrl"
          title="Instagram Link"
          placeholder="https://instagram.com/your-page"
        />
        <Form.FieldInput
          name="metadata.twitterUrl"
          title="Twitter Link"
          placeholder="https://twitter.com/your-page"
        />
        <Form.FieldInput
          name="metadata.email"
          title="Email Address"
          placeholder="email@address.com"
        />
        <Button
          type="submit"
          icon="floppy-disk"
          text="Save Details"
          disabled={isSubmitting}
        />
      </Form.StyledForm>
    )}
  </Formik>
);

export default OldBusinessForm;

import * as React from 'react';
import { Button } from '@humancollective/uikit-web';
import firebase from 'firebase/app';
import 'firebase/storage';

import { FirebaseContext } from '../../contexts';
import { createId } from '../../utilities';

export interface ImageUploaderNew {
  onUploadSuccess: (filePath: string) => void;
}

const getFileType = (fileName: string) => fileName.split('.').pop();

const ImageUploaderNew: React.FunctionComponent<ImageUploaderNew> = ({
  onUploadSuccess,
}) => {
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const firebaseContext = React.useContext(FirebaseContext);
  const uid = firebaseContext.firebaseUser && firebaseContext.firebaseUser.uid;
  const [storageRef, setStorageRef] = React.useState<
    undefined | firebase.storage.Reference
  >();
  const [uploadProgress, setUploadProgress] = React.useState<number>(0);

  React.useEffect(() => {
    setStorageRef(firebase.storage().ref(`/uploads/${uid}`));
  }, [uid]);

  const onClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleError = (error: Error) => {
    console.log('Error', error);
    setUploadProgress(0);
  };

  const handleSuccess = (fileName: string) => {
    setUploadProgress(0);
    const filePath = `/uploads/${uid}/${fileName}`;
    onUploadSuccess(filePath);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const fileToUpload: undefined | File = files ? files[0] : undefined;

    if (fileToUpload && storageRef) {
      const fileType = getFileType(fileToUpload.name);
      const fileName = `${createId()}.${fileType}`;
      // Sets the file name to a random hash & uses the original image extension
      const uploadTask = storageRef.child(`/${fileName}`).put(fileToUpload);

      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        progress => {
          const uploadPercent =
            (progress.bytesTransferred / progress.totalBytes) * 100;
          setUploadProgress(Number(uploadPercent.toFixed(0)));
        },
        handleError,
        () => handleSuccess(fileName)
      );
    }
  };

  return (
    <div onClick={onClick}>
      <input
        style={{ display: 'none' }}
        type="file"
        ref={fileInputRef}
        onChange={handleChange}
      />
      <Button
        label={
          uploadProgress > 0
            ? `Uploading ${uploadProgress}%...`
            : 'Upload Image'
        }
        onClick={onClick}
        secondary={true}
        type="button"
      />
    </div>
  );
};

export default ImageUploaderNew;

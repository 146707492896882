import React from 'react';
import FileUploader from 'react-firebase-file-uploader';
import firebase from 'firebase/app';
import 'firebase/storage';

import { FirebaseContext } from '../../contexts';
import { Icon, Spinner } from '@blueprintjs/core';

interface UploadProgressState {
  value: number;
}

interface ImageUploaderProps {
  onChange: ({ imagePath }: { imagePath: string }) => void;
  label?: string;
}

const EditBlockImage: React.FunctionComponent<ImageUploaderProps> = ({
  onChange,
  label = 'Upload Image',
}) => {
  const fileUploader = React.useRef(null);
  const [metadata, setMetadata] = React.useState(null);
  const firebaseContext = React.useContext(FirebaseContext);
  const uid = firebaseContext.firebaseUser && firebaseContext.firebaseUser.uid;
  const [storageRef, setStorageRef] = React.useState(null);
  const [uploadProgress, setUploadProgress] = React.useState<
    UploadProgressState
  >({ value: 0 });

  React.useEffect(() => {
    setStorageRef(firebase.storage().ref(`/uploads/${uid}`));
  }, [uid]);

  const handleChange = ({ target: { files } }) => {
    const img = new Image();
    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      window.URL.revokeObjectURL(img.src);
      const metadata = { width, height };
      const file = files[0];
      setMetadata(metadata);
      fileUploader.current.startUpload(file);
    };
    img.src = window.URL.createObjectURL(files[0]);
  };

  const handleUploadSuccess = async (filename: string) => {
    const imagePath = `/uploads/${uid}/${filename}`;
    onChange({ imagePath, metadata });
    setMetadata(null);
    setUploadProgress({ value: 0 });
  };

  const handleUploadError = () => {
    setUploadProgress({ value: 0 });
  };

  return storageRef ? (
    <label className="bp3-button">
      {uploadProgress.value > 0 ? (
        <Spinner size={Spinner.SIZE_SMALL} value={uploadProgress.value / 100} />
      ) : (
        <Icon icon="upload" iconSize={15} />
      )}
      {label}
      <FileUploader
        ref={fileUploader}
        hidden={true}
        accept="image/*"
        name="image"
        randomizeFilename={true}
        storageRef={storageRef}
        onChange={handleChange}
        onUploadSuccess={handleUploadSuccess}
        onUploadError={handleUploadError}
        onProgress={(value: number) => setUploadProgress({ value })}
      />
    </label>
  ) : null;
};

export default EditBlockImage;

import * as React from 'react';
import { Text } from '@humancollective/uikit-web';
import styled from 'styled-components';

interface FormErrorProps {
  children: string;
}

const StyledText = styled(Text)`
  display: block;
  margin-bottom: 5px;
`;

const FormError: React.FunctionComponent<FormErrorProps> = ({ children }) => (
  <StyledText as="span" variant="error">
    {children}
  </StyledText>
);

export default FormError;

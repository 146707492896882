import * as React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { OldBusinessForm } from '../components/Forms/Business';
import Navigation from '../components/Navigation';
import { SeedzContextProvider, UserBusinessContext } from '../contexts';
import Loading from '@humancollective/human-dashboard/shared/src/components/Loading';

const StyledProfilePage = styled.div`
  .profile-page__form {
    display: flex;
    max-width: 1020px;
    margin: 45px auto;
  }

  .profile-page__logo {
    height: 36px;
    width: 36px;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    border: 1px solid #ccc;
    margin: 12px 0 9px;
  }
`;

const BusinessPage = () => {
  const userBusiness = React.useContext(UserBusinessContext);
  return userBusiness ? (
    <StyledProfilePage>
      <Helmet>
        <title>Business - Seedz</title>
      </Helmet>
      <Navigation />
      <div className="profile-page__form">
        <OldBusinessForm business={userBusiness} />
      </div>
    </StyledProfilePage>
  ) : (
    <Loading />
  );
};

const BusinessPageProviders: React.FunctionComponent = () => (
  <SeedzContextProvider>
    <BusinessPage />
  </SeedzContextProvider>
);

export default BusinessPageProviders;

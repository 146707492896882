import { FormGroup, InputGroup } from '@blueprintjs/core';
import { BusinessMetadata } from '@humancollective/seedz-shared';
import { Formik, FormikProps } from 'formik';
import * as React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { Button, Colors } from '@humancollective/uikit-web';

import { FormError } from '../Fields';
import { updateBusinessById } from '../../../utilities/firebase';

const StyledForm = styled.form`
  max-width: 350px;
  .bp3-form-group {
    > label.bp3-label {
      color: ${Colors.primary};
      font-size: 16px;
      font-weight: bold;
    }
  }
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 320px;
`;

const urlValidation = yup.string().url('Invalid URL');

const validationSchema = yup.object().shape<BusinessMetadata>({
  facebookUrl: urlValidation,
  twitterUrl: urlValidation,
  instagramUrl: urlValidation,
  email: yup.string().email('Invalid email'),
  // https://stackoverflow.com/a/29767609
  phone: yup
    .string()
    .matches(
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
      'Invalid phone number'
    ),
  website: urlValidation,
});

const DEFAULT_VALUES: BusinessMetadata = {
  facebookUrl: '',
  twitterUrl: '',
  instagramUrl: '',
  website: '',
  email: '',
  phone: '',
};

interface BusinessSocialFormProps {
  businessId: string;
  onSuccess?: () => void;
  onSkipPress?: () => void;
}

export const BusinessSocialForm: React.FunctionComponent<
  BusinessSocialFormProps
> = ({ businessId, onSuccess, onSkipPress }) => (
  <Formik
    initialValues={DEFAULT_VALUES}
    onSubmit={async (values: BusinessMetadata, { setSubmitting }) => {
      try {
        setSubmitting(true);
        // Save the business in firebase
        await updateBusinessById(businessId, { metadata: values });

        // onSuccess callback
        if (onSuccess) onSuccess();

        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
      }
    }}
    validationSchema={validationSchema}
    enableReinitialize={true}
  >
    {({
      isSubmitting,
      values,
      handleSubmit,
      handleChange,
      errors,
    }: FormikProps<BusinessMetadata>) => (
      <StyledForm onSubmit={handleSubmit}>
        <FormGroup label="Website link" labelFor="website">
          {errors.website && <FormError>{errors.website}</FormError>}
          <InputGroup
            name="website"
            placeholder="https://website.com"
            value={values.website}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup label="Phone Number" labelFor="phone">
          {errors.phone && <FormError>{errors.phone}</FormError>}
          <InputGroup
            name="phone"
            placeholder="999-999-9999"
            onChange={handleChange}
            value={values.phone}
          />
        </FormGroup>
        <FormGroup label="Email Adress" labelFor="email">
          {errors.email && <FormError>{errors.email}</FormError>}
          <InputGroup
            name="email"
            placeholder="hello@business.com"
            onChange={handleChange}
            value={values.email}
          />
        </FormGroup>
        <FormGroup label="Facebook link" labelFor="facebookUrl">
          {errors.facebookUrl && <FormError>{errors.facebookUrl}</FormError>}
          <InputGroup
            name="facebookUrl"
            placeholder="https://facebook.com/yourbusiness"
            onChange={handleChange}
            value={values.facebookUrl}
          />
        </FormGroup>
        <FormGroup label="Twitter link" labelFor="twitterUrl">
          {errors.twitterUrl && <FormError>{errors.twitterUrl}</FormError>}
          <InputGroup
            name="twitterUrl"
            placeholder="https://twitter.com/mybusiness"
            onChange={handleChange}
            value={values.twitterUrl}
          />
        </FormGroup>
        <FormGroup label="Instagram link" labelFor="instagramUrl">
          {errors.instagramUrl && <FormError>{errors.instagramUrl}</FormError>}
          <InputGroup
            name="instagramUrl"
            placeholder="https://instagram.com/businessgoeshere"
            onChange={handleChange}
            value={values.instagramUrl}
          />
        </FormGroup>
        <ButtonContent>
          <Button type="submit" label="Save Details" disabled={isSubmitting} />
          {onSkipPress && (
            <Button label="Skip" onClick={onSkipPress} secondary={true} />
          )}
        </ButtonContent>
      </StyledForm>
    )}
  </Formik>
);

export default BusinessSocialForm;
